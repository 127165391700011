import { loadTranslation } from '@lib/i18n'

function Custom404Component() {
  return (
    <div className="flex h-screen flex-auto flex-col items-center justify-center px-4 text-center sm:flex-row">
      <h1 className="text-2xl font-extrabold tracking-tight text-primary-500 dark:text-primary-200 sm:mr-6 sm:border-r sm:border-primary-900/10 sm:pr-6 sm:text-3xl sm:dark:border-primary-300/10">
        404
      </h1>
      <h2 className="mt-2 text-lg text-primary-400 dark:text-primary-400 sm:mt-0">
        This page could not be found.
      </h2>
    </div>
  )
}

export async function getStaticProps({ locale }) {
  const translation = await loadTranslation(
    locale,
    process.env.NODE_ENV === 'production'
  )
  return {
    props: {
      translation,
    },
  }
}

export default Custom404Component
